<template>
  <div
      data-aos="fade-up"
      data-aos-duration="1000"
      class="container slider-box inside-wrapper-margin">
    <div class="case_wrapper">
      <div class="container-fluid">
        <!-- First row Start -->
        <div class="row first_row">
          <div class="col-lg-6 col-md-8 col-sm-9">
            <h2>Problem</h2>

            <p>
              Cyberbullying is a form of bullying or harassment, typically by
              teens, particularly on social media sites like
              <a href="#">Twitter</a> or <a href="#">Facebook</a>.
            </p>
          </div>

          <div class="col-lg-6 col-md-4 col-sm-3 float_img">
            <img
              src="../../assets/img/casestudy/case-bee.png"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- First row End -->
        <!-- Second row Start -->
        <div class="row second_row">
          <div class="col-lg-6 col-md-12 float_img">
            <img
              src="../../assets/img/casestudy/vector2.png"
              class="img-fluid"
            />
          </div>

          <div class="col-lg-6 col-md-12 align-self-center">
            <ul>
              <li>
                <h2>34%</h2>
                <p>
                  of cyberbullying victims are willing to admit it to their
                  parents.
                </p>
              </li>

              <li>
                <h2>21%</h2>
                <p>
                  of victims of bullying are hight school girls with a different
                  skin color.
                </p>
              </li>

              <li>
                <h2>42%</h2>
                <p>of LGBT youth have experienced cyberbullying.</p>
              </li>

              <li>
                <h2>66%</h2>
                <p>
                  of female victims have feelings of powerlessness because of
                  cyberbullying.
                </p>
              </li>

              <li class="text-right">
                <span
                  >(Source:
                  <a href="https://techjury.net">https://techjury.net</a>)</span
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- Second row End -->
        <!-- Third row Start -->
        <div class="row third_row">
          <div class="col-md-12">
            <h3>Feelings associated with victims:</h3>
          </div>
          <div class="col-md-4 col-sm-12">
            <img
              src="../../assets/img/casestudy/vector3.png"
              class="img-fluid"
            />
          </div>

          <div class="col-md-4 col-sm-12">
            <ul>
              <li>Anger</li>
              <li>Isolation</li>
              <li>Depression</li>
              <li>Anxiety</li>
              <li>Physical Illness</li>
              <li>Humiliation</li>
              <li>Suicide</li>
            </ul>
          </div>

          <div class="col-md-4 col-sm-12">
            <img
              src="../../assets/img/casestudy/vector4.png"
              class="img-fluid"
            />
          </div>

          <div class="col-md-12">
            <p>
              Cyberbullying is not only an issue in the United States, about 12%
              of girls aged 15 report having been syberbullied, compared to 8%
              for boys.
            </p>

            <p>
              Girls report being targeted through digital media more often tham
              boys in all OECD countries except Denmark, Israel and Spain.
            </p>

            <span class="pull-left"
              >(source: <a href="www.oecd.org">www.oecd.org</a>)</span
            >
          </div>
        </div>
        <!-- Third row End -->
        <!-- Forth row Start -->
        <div class="row forth_row">
          <div class="col-md-6 col-sm-12 align-self-center">
            <h3>Advice:</h3>
            <ul>
              <li>Limit what you share online</li>
              <li>Keep the evidence</li>
              <li>Talk to Someone</li>
              <li>Be Respectful to Others</li>
            </ul>
          </div>

          <div class="col-md-6 col-sm-12 text-center">
            <img
              src="../../assets/img/casestudy/vector5.png"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Forth row End -->
        <!-- Fifth row Start -->
        <div class="row fifth_row">
          <div class="col-md-12">
            <h1>Action:</h1>

            <h5>Call 1-800-VICTIMS (842-8467)</h5>
            <h5>Stay Cyber Safe by using apps like <b>BullyBeeGone</b></h5>

            <p>
              <b>BullyBeeGone</b> is a mobile application created from the
              collaboration of <b>DoSomething Strategic</b> and
              <b>Ocean Ring Technologies</b> for both iOS and Android devices.
              This application allows users to filter messages that contain over
              400 previously identified words and phrases used in cyberbullying.
              Allowing for muting of tweets on <b>Twitter</b> and automatically
              silencing messages of selected users.
            </p>
          </div>

          <div class="col-12">
            <img
              src="../../assets/img/casestudy/vector6.jpg"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Fifth row End -->
      </div>
    </div>
    <div class="row contact-fourth back-red nopadding">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Home
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/news">
          <h2 class="heading text-light">
            News <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "work",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
